<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">直播管理（小程序）</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">直播列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="直播标题" class="searchboxItem ci-full">
              <span class="itemLabel">直播标题:</span>
              <el-input
                  v-model="liveTitle"
                  type="text"
                  size="small"
                  clearable
                  placeholder="请输入直播标题"
              />
            </div>
            <div title="直播间状态" class="searchboxItem ci-full">
              <span class="itemLabel">直播间状态:</span>
              <el-select
                  clearable
                  size="small"
                  v-model="roomState"
                  placeholder="请选择"
              >
                <el-option
                    v-for="item in liveTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
            >查询</el-button
            >
            <div class="btnBox" style="margin-left:20px">
              <el-button class="bgc-bv" round @click="handleCreate('add')"
              >创建直播间</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  width="100px"
                  :index="indexMethod"
                  fixed
              />

              <el-table-column
                  label="直播标题"
                  align="center"
                  prop="liveTitle"
                  show-overflow-tooltip
                  min-width="150"
                  fixed
              />

              <el-table-column
                  label="直播老师微信号"
                  align="center"
                  prop="username"
                  show-overflow-tooltip
                  min-width="120"
              >
              </el-table-column>
              <el-table-column
                  label="直播老师昵称"
                  align="center"
                  prop="liveNickname"
                  show-overflow-tooltip
                  min-width="100"
              />
              <el-table-column
                  label="直播开始时间"
                  align="center"
                  prop="startTime"
                  show-overflow-tooltip
                  min-width="150"
              >
                <template slot-scope="scope">{{
                    scope.row.startTime | moment
                  }}</template>
              </el-table-column>
              <el-table-column
                  label="直播结束时间"
                  align="center"
                  prop="applyTime"
                  show-overflow-tooltip
                  min-width="150"
              >
                <template slot-scope="scope">{{
                    scope.row.endTime | moment
                  }}</template>
              </el-table-column>
              <el-table-column
                  label="直播间密码"
                  align="center"
                  prop="liveMmValue"
                  show-overflow-tooltip
                  min-width="100"
              >
              </el-table-column>
              <el-table-column
                  label="直播类型"
                  align="center"
                  prop="liveType"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{ scope.row.liveType == "10" ? "手机直播" : "推流设备直播" }}
                </template>
              </el-table-column>
              <el-table-column
                  label="直播状态"
                  align="center"
                  prop="roomState"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                        "MINI_LIVE_STATUS",
                        scope.row.roomState || "--"
                    )
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  label="审核状态"
                  align="center"
                  prop="auditState"
                  show-overflow-tooltip
                  min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AUDITSTATE", scope.row.auditState || "--")
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  label="审核不通过原因"
                  align="center"
                  prop="auditReason"
                  show-overflow-tooltip
                  min-width="120"
              />
              <el-table-column
                  label="操作"
                  align="center"
                  fixed="right"
                  min-width="200"
              >
                <template slot-scope="scope">
                  <template v-if="scope.row.liveType == '20' && scope.row.auditState == '30'">
                    <el-popover
                        placement="left"
                        title="推流地址"
                        width="350"
                        trigger="click"
                        :content="pushAddress"
                        @hide="hide"
                    >
                      <p>在第三方推流应用中,以下地址进行推流,复制链接:{{pushAddress}}</p>

                      <p>注:此地址为当前直播间唯一推流地址，不要泄露给第三方。</p>
                      <p>备注</p>
                      <p>服务器地址:{{serverAddress}}</p>
                      <p>串流密钥:{{streamingKey}}</p>
                      <el-button slot="reference"  type="text"  size="mini"
                                 style="padding:0 5px"  @click="doLookPushAddress(scope.row.liveId)">查看推流地址</el-button>
                    </el-popover>
                  </template>
                  <template
                      v-if="
                      scope.row.auditState == '10' ||
                        scope.row.auditState == '40'
                    "
                  >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="handleCreate(scope.row.liveId, 'edit')"
                    >编辑</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="doDelete(scope.row.liveId)"
                    >删除</el-button
                    >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="doSure(scope.row.liveId)"
                    >提交审核</el-button
                    >
                  </template>
                  <template
                      v-if="
                      scope.row.auditState == '20' ||
                        scope.row.auditState == '30'
                    "
                  >
                    <el-button
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="handleCreate(scope.row.liveId, 'detail')"
                    >详情</el-button
                    >
                    <el-button
                        v-if="scope.row.roomState != '102' && scope.row.roomState != '-'"
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="watchList(scope.row.liveId)"
                    >观看列表</el-button
                    >
                    <el-button
                        v-if="scope.row.liveHf &&  scope.row.roomState == '103'"
                        type="text"
                        size="mini"
                        style="padding:0 5px"
                        @click="handleplayback(scope.row.liveId,scope.row.enablePlayback)"
                    >{{scope.row.enablePlayback ? '关闭':'开启'}}回放</el-button
                    >
                    <!-- <el-button
                    v-if="scope.row.liveHf &&  scope.row.roomState == '103'"
                      type="text"
                      size="mini"
                      style="padding:0 5px"
                      @click="handleplaybackClose(scope.row.liveId)"
                      >{{!scope.row.enablePlayback ? '关闭':'开启'}}回放</el-button
                    > -->
                  </template>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "teacherList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      dialogFormVisible: false,
      liveTitle: "",
      roomState: "",
      liveTypeList: [],
      pushAddress: "", //推流地址
      serverAddress:'',
      streamingKey:'',
    };
  },
  computed: {},
  created() {
    this.getliveTypeList();
    this.getTableHeight();
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.liveTitle) {
        params.liveTitle = this.liveTitle;
      }
      if (this.roomState) {
        params.roomState = this.roomState;
      }
      this.doFetch(
          {
            url: "/room/miniapp-live-room/pageList",
            params,
            pageNum,
          },
          true,
          2
      );
    },
    // 获取直播状态
    getliveTypeList() {
      const liveState = this.$setDictionary("MINI_LIVE_STATUS", "list");
      const list = [];
      for (const key in liveState) {
        list.push({
          value: key,
          label: liveState[key],
        });
      }
      this.liveTypeList = list;
    },
    //新增-编辑
    handleCreate(liveId = "", stu) {
      this.$router.push({
        path: "/web/miniApp/liveReviewDetails",
        query: {
          stu,
          liveId,
        },
      });
    },
    /* 查看推流地址 */
    doLookPushAddress(liveId) {
      this.$post(
          "/room/miniapp-live-room/getPushUrl",
          { liveId },
          3000,
          true,
          2
      )
          .then((ret) => {
            console.log(ret.data);
            this.pushAddress = ret.data;
            var index3=ret.data.lastIndexOf("/");
            this.serverAddress = ret.data.substring(0,index3+1)
            this.streamingKey = ret.data.substring(index3+1)
            //   this.coptLen(index)
          })
          .catch((err) => {
            return;
          });
    },
    hide() {
      this.pushAddress = '';
      this.serverAddress = ''
      this.streamingKey='';
    },
    // coptLen(index) {
    //        const _this = this
    //        let copy = _this.$refs['copy' + index]
    //       let  clipboard = new Clipboard(copy)
    //       clipboard.on('success', function (val) {
    //         // _this.disabled = true
    //         setTimeout(() => {
    //         //   _this.disabled = false
    //           // 销毁实例防止多次触发
    //           clipboard.destroy()
    //         }, 1000)
    //         _this.$notify({
    //     title: '成功',
    //     message: '恭喜您复制成功，赶快去粘贴吧~',
    //     type: 'success',
    //     showClose: false
    // });
    //       })
    //       clipboard.on('error', function () {
    //         _this.$message.error('复制失败，请手动复制')
    //       })
    //     },
    /* 删除 */
    doDelete(liveId) {
      this.doDel(
          {
            url: "/room/miniapp-live-room/delete",
            msg: "你确定要删除该直播吗？",
            ps: {
              type: "post",
              data: { liveId },
            },
          },
          true,
          2
      );
    },
    /* 开启&&关闭回放 */
    handleplayback(liveId,enablePlayback){
      this.$post(enablePlayback ? '/room/miniapp-live-room/disablePlayback':'/room/miniapp-live-room/enablePlayback',{liveId},3000,true,2).then(ret => {
        if(ret.status == '0') {
          this.$message({
            message:ret.message,
            type:'success'
          })
          this.getData(-1)
        }
      }).catch(err => {
        return
      })

    },
    /* 提交审核 */
    doSure(liveId) {
      this.$post('/room/miniapp-live-room/commitAudit',{liveId},3000,true,2).then(ret => {
        if(ret.status == '0') {
          this.$message({
            message:'提交审核成功',
            type:'success'
          });
          this.getData(-1)
        }
      }).catch(err => {
        return;
      })
    },
    /* 观看列表 */
    watchList(liveId) {
      this.$router.push({
        path: "/web/miniApp/liveRoomManager",
        query: {
          liveId,
        },
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
          window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}

.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
</style>
<style lang="less" scoped></style>
